import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GenericDrawer from '../../Common/GenericDrawer';
import { IPurchaseOrderApproval } from '../../../interfaces/PurchaseOrderApproval';
import { IPurchaseOrder } from '../../../containers/Order/PurchaseOrderAssets';
import { defaultColumns } from '../../../constants/purchaseOrdersColumns';
import { formatOrRenderValue } from '../../../helpers/Utils';
import { purchaseOrderApprovalStatusDetailed } from '../../../constants/purchaseOrderApprovalStatus';

interface IDetailsDrawerProps {
	openDetails?: IPurchaseOrderApproval | IPurchaseOrder;
	onClose: () => void;
}

const DetailsDrawer = ({
	openDetails,
	onClose,
}: IDetailsDrawerProps): JSX.Element => {
	const contentMemo = useMemo(
		() => (
			<Grid container spacing={1}>
				{openDetails && 'status' in openDetails && (
					<Grid item xs={6}>
						<Typography variant="subtitle2">Status</Typography>
						<Box>
							{purchaseOrderApprovalStatusDetailed[openDetails.status] || '-'}
						</Box>
					</Grid>
				)}
				{defaultColumns.map((column) => {
					if (openDetails) {
						const { field } = column;
						const value = openDetails[field as keyof typeof openDetails];
						const gridSize = (field === 'observation' || field === 'actionJustificative') ? 12 : 6;

						if (value !== undefined) {
							const displayValue = formatOrRenderValue(column, value);
							return (
								(field in openDetails) && (
									<Grid item xs={gridSize} key={field}>
										<Typography variant="subtitle2">{column.headerName}</Typography>
										<Box>{displayValue}</Box>
									</Grid>
								)
							);
						}
					}
					return null;
				})}
			</Grid>
		),
		[openDetails],
	);

	return (
		<GenericDrawer
			open={Boolean(openDetails)}
			title="Detalhes do Pedido"
			content={contentMemo}
			onClose={onClose}
		/>
	);
};

DetailsDrawer.defaultProps = {
	openDetails: undefined,
};

export default DetailsDrawer;
