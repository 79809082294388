import {
	GridColDef, GridValueFormatterParams,
} from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import { currencyBRLMask } from '../helpers/intl';
import { Status } from '../enums/SaleOrderStatusType';
import { SaleOrderStatusLabels } from './saleOrderStatusLabels';
import { formatValueOrTrace } from '../helpers/Utils';
import { DeliveryTypeLabels } from './deliveryTypeLabels';
import { DeliveryType } from '../enums/DeliveryType';
import { PaymentMethod } from '../enums/PaymentMethod';
import { PaymentMethodTypeLabels } from './paymentMethodTypeLabels';

export const saleOrderColumns: GridColDef[] = [
	{
		field: 'code',
		headerName: 'Número do Pedido',
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams) => {
			const numericValue = Number(params.value);
			if (!Number.isNaN(numericValue)) {
				const formattedValue = `OV-${String(numericValue).padStart(6, '0')}`;
				return formattedValue;
			}
			return params.value;
		},
	},
	{
		field: 'createdAt',
		headerName: 'Data do Pedido',
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams) => {
			const date = new Date(params.value);
			return (
				date.toLocaleString('pt-BR')
			);
		},
	},
	{
		field: 'clientName',
		headerName: 'Nome do Cliente',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'salesman',
		headerName: 'Nome do Vendedor',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'totalOrder',
		headerName: 'Valor Total',
		align: 'left',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		field: 'status',
		headerName: 'Status',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => SaleOrderStatusLabels[params.value as Status],
	},
	{
		field: 'deliveryAddress',
		headerName: 'Endereço de Entrega',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'discount',
		headerName: 'Desconto',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		field: 'deliveryType',
		headerName: 'Tipo de Entrega',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => DeliveryTypeLabels[params.value as DeliveryType],
	},
	{
		field: 'volumeQuantity',
		headerName: 'Quantidade de Volumes',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'grossWeight',
		headerName: 'Peso Bruto',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => `${new Decimal(params.value as string).toFixed(2)} kg`,
	},
	{
		field: 'netWeight',
		headerName: 'Peso Líquido',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => `${new Decimal(params.value as string).toFixed(2)} kg`,
	},
	{
		field: 'paymentMethod',
		headerName: 'Método de Pagamento',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => PaymentMethodTypeLabels[params.value as PaymentMethod],
	},
	{
		field: 'description',
		headerName: 'Descrição',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'actionJustificative',
		headerName: 'Justificativa de Cancelamento',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
];

export const tableAddressColumns: GridColDef[] = [
	{
		field: 'address',
		headerName: 'Endereço',
		editable: false,
		flex: 2,
	},
	{
		field: 'amount',
		headerName: 'Quantidade',
		editable: true,
		flex: 1,
		type: 'number',
		align: 'left',
		headerAlign: 'left',
	},
	{
		field: 'needDate',
		headerName: 'Data da Necessidade',
		editable: false,
		flex: 1,
	},
];
