import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { SalePriceFormationStatus } from '../enums/SalePriceFormationStatus';
import { formatCode, formatValueOrTrace } from '../helpers/Utils';
import { SalePriceFormationStatusTypeLabels } from './salePriceFormationStatusTypeLabels';
import { currencyBRLMask } from '../helpers/intl';

export const monthMap: { [key: string]: number } = {
	JAN: 0,
	FEV: 1,
	MAR: 2,
	ABR: 3,
	MAI: 4,
	JUN: 5,
	JUL: 6,
	AGO: 7,
	SET: 8,
	OUT: 9,
	NOV: 10,
	DEZ: 11,
};

const formatMonthYear = (dateString: string): string => {
	const [year, month] = dateString.split('-').map(Number);
	const monthName = Object.keys(monthMap).find((key) => monthMap[key] === month - 1);
	return `${monthName}/${year}`;
};

export const salePriceFormationColumns: GridColDef[] = [
	{
		field: 'code',
		headerName: 'Código',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatCode(params.value),
	},
	{
		field: 'revision',
		headerName: 'Revisão',
		flex: 0.5,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'priceList',
		headerName: 'Tabela',
		flex: 0.5,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'productCode',
		headerName: 'Cód. Produto',
		flex: 0.6,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'productDescription',
		headerName: 'Desc. Produto',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'productFamilyCode',
		headerName: 'Cód. Família',
		flex: 0.6,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'productFamilyDescription',
		headerName: 'Desc. Família',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value || '-'} />
		),
	},
	{
		field: 'status',
		headerName: 'Status',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<SalePriceFormationStatus>) => (
			params.value != null ? SalePriceFormationStatusTypeLabels[params.value] : '-'
		),
	},
	{
		field: 'totalDirectCost',
		headerName: 'Custos Diretos',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? currencyBRLMask(params.value, { decimalPlaces: 3 }) : '-'),
	},
	{
		field: 'salePrice',
		headerName: 'Preço de Venda',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? currencyBRLMask(params.value, { decimalPlaces: 3 }) : '-'),
	},
	{
		field: 'totalIndirectCost',
		headerName: 'Custos Indiretos',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? `${params.value.toFixed(3).replace('.', ',')}%` : '-'),
	},
	{
		field: 'baseCommission',
		headerName: 'Comissão Base',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? `${params.value.toFixed(1).replace('.', ',')}%` : '-'),
	},
	{
		field: 'averageTerm',
		headerName: 'Prazo Médio',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? `${params.value.toFixed(3)}%` : '-'),
	},
	{
		field: 'costBaseMonth',
		headerName: 'Mês Base de Custo',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? formatMonthYear(params.value) : '-'),
	},
	{
		field: 'validity',
		headerName: 'Ínicio da Vigência',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? new Date(params.value).toLocaleDateString() : '-'),
	},
	{
		field: 'createdAt',
		headerName: 'Data da Integração',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? new Date(params.value).toLocaleDateString() : '-'),
	},
	{
		field: 'updatedAt',
		headerName: 'Ultima Modificação',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? new Date(params.value).toLocaleDateString() : '-'),
	},
	{
		field: 'informed',
		headerName: 'Informado',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? currencyBRLMask(params.value, { decimalPlaces: 3 }) : '-'),
	},
	{
		field: 'userResponsible',
		headerName: 'Responsável',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'actionJustificative',
		headerName: 'Justificativa',
		flex: 1,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
];
