/* eslint-disable import/no-duplicates */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import { StatusOperationLabels } from './statusOperationLabels';
import { StatusOperation } from '../containers/StatusSaleOrder/StatusSaleOrderAssets';
import { applyAppropriateMask } from '../helpers/masks';
import { currencyBRLMask } from '../helpers/intl';
import { formatDateToString } from '../helpers/Utils';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

export const statusSaleOrderColumns: GridColDef[] = [
	{
		field: 'statusOperation',
		headerName: 'Status',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={StatusOperationLabels[params.value as StatusOperation]}>
				<Box component="span">{StatusOperationLabels[params.value as StatusOperation]}</Box>
			</Tooltip>
		),
	},
	{
		field: 'code',
		headerName: 'Pedido',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={params.value}>
				<Box component="span">{params.value}</Box>
			</Tooltip>
		),
	},
	{
		field: 'emissionDate',
		headerName: 'Emissão',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${formatDateToString(params.row.emissionDate)}`}>
				<Box component="span">{formatDateToString(params.row.emissionDate)}</Box>
			</Tooltip>
		),
	},
	{
		field: 'totalOrder',
		headerName: 'Total',
		align: 'right',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${currencyBRLMask(params.value)}`}>
				<Box component="span">{currencyBRLMask(params.value)}</Box>
			</Tooltip>
		),
	},
	{
		field: 'clientTaxId',
		headerName: 'CNPJ/CPF',
		flex: 0.25,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${applyAppropriateMask(params.value)}`}>
				<Box component="span">{applyAppropriateMask(params.value)}</Box>
			</Tooltip>
		),
	},
	{
		field: 'clientName',
		headerName: 'Cliente',
		flex: 0.6,
		renderCell: (params: GridRenderCellParams<string>) => (
			<Tooltip title={`${params.row.clientName}`}>
				<TruncateTooltipCell value={`${params.row.clientCode} - ${params.row.clientName}`} />
			</Tooltip>
		),
	},
	{
		field: 'courierName',
		headerName: 'Transportadora',
		flex: 0.5,
		valueFormatter: (params) => applyAppropriateMask(params.value),
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${params.value}`}>
				<Box component="span">{params.value}</Box>
			</Tooltip>
		),
	},
	{
		field: 'invoice',
		headerName: 'Nota Fiscal / Série',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => {
			const { statusInvoiceItems } = params.row;
			const invoiceInfo = statusInvoiceItems && statusInvoiceItems.length > 0
				? `${statusInvoiceItems[0].nrInvoice}/${statusInvoiceItems[0].serie}`
				: '-';
			return (
				<Tooltip title={`${invoiceInfo}`}>
					<Box component="span">{invoiceInfo}</Box>
				</Tooltip>
			);
		},
	},
	{
		field: 'shippedUserName',
		headerName: 'Operador',
		flex: 0.3,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${params.row?.statusInvoiceItems[0]?.shippedUserName || '-'}`}>
				<Box component="span">{params.row?.statusInvoiceItems[0]?.shippedUserName || '-'}</Box>
			</Tooltip>
		),
	},
	{
		field: 'shippedDate',
		headerName: 'Data do Checkout',
		flex: 0.25,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${params.row?.statusInvoiceItems[0]?.shippedDate ? format(new Date(params.row?.statusInvoiceItems[0]?.shippedDate), 'dd/MM/yyyy HH:mm') : '-'}`}>
				<Box component="span">{`${params.row?.statusInvoiceItems[0]?.shippedDate ? format(new Date(params.row?.statusInvoiceItems[0]?.shippedDate), 'dd/MM/yyyy HH:mm') : '-'}`}</Box>
			</Tooltip>
		),
	},
	{
		field: 'clientBranch',
		headerName: 'Filial',
		width: 120,
		renderCell: (params: GridRenderCellParams) => (
			<Tooltip title={`${params.value}`}>
				<Box component="span">{params.value}</Box>
			</Tooltip>
		),
	},
];
