/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import JsPDF from 'jspdf';
import autoTable, { CellInput, HAlignType, VAlignType } from 'jspdf-autotable';
import Decimal from 'decimal.js';
import { ConversionType } from '../../enums/ConversionType';
import { currencyBRLMask } from '../../helpers/intl';
import { InventoryTask, Task } from '../../containers/Task/InventoryTaskAssets';

export const generatePDF = async (
	orderData: InventoryTask,
	subgridItems: Task[],
	logoUrl: string,
) => {
	const doc = new JsPDF('landscape');
	const totalPagesExp = '{total_pages_count_string}';

	const addFooters = (doc: JsPDF, pageNumber: number): void => {
		const pageHeight = doc.internal.pageSize.getHeight();
		const pageWidth = doc.internal.pageSize.getWidth();

		doc.setFontSize(10);
		doc.setLineWidth(0.5);
		doc.line(10, pageHeight - 15, pageWidth - 10, pageHeight - 15);
		doc.text('Desenvolvido por MAIS i9 Tecnologia', 14, pageHeight - 10, { align: 'left' });
		doc.text(`Página ${pageNumber}`, pageWidth - 20, pageHeight - 10, { align: 'right' });
	};

	let logoExists = false;
	let imgLogo: HTMLImageElement | null = null;

	try {
		const response = await fetch(logoUrl);
		logoExists = response.ok;
		if (logoExists) {
			const blob = await response.blob();
			imgLogo = await new Promise<HTMLImageElement>((resolve) => {
				const image = new Image();
				image.onload = () => resolve(image);
				image.src = URL.createObjectURL(blob);
			});
		}
	} catch (error) {
		console.warn('Não foi possível carregar a logo:', error);
		logoExists = false;
	}

	const title = 'Romaneio - Tarefas de Expedição';
	const pageWidth = doc.internal.pageSize.getWidth();
	const logoWidth = logoExists && imgLogo ? 40 : 0;
	const logoHeight = logoExists && imgLogo ? (imgLogo.height / imgLogo.width) * logoWidth : 0;

	const titleXPos = (pageWidth - doc.getTextWidth(title)) / 2;

	if (logoExists && imgLogo) {
		doc.addImage(imgLogo, 'PNG', 14, 10, logoWidth, logoHeight);
	}

	doc.setFontSize(16);
	const titleYPos = logoExists ? 10 + logoHeight / 2 + 4 : 20;
	doc.text(title, titleXPos, titleYPos);

	doc.setLineWidth(0.5);
	const lineYPos = logoExists ? logoHeight + 15 : 32;
	doc.line(10, lineYPos, pageWidth - 10, lineYPos);

	const leftColumnX = 14;
	const rightColumnX = pageWidth / 2 + 20;

	doc.setFontSize(10);
	doc.text(`Empresa/Filial: ${orderData.branch.company.name} / ${orderData.branch.name}`, leftColumnX, lineYPos + 10);
	doc.text(`Cliente/Fornecedor: ${orderData.businessPartnerName ? orderData.businessPartnerName : '-'}`, leftColumnX, lineYPos + 16);

	doc.text(`Pedido de Venda: ${orderData.saleOrder}`, rightColumnX, lineYPos + 10);
	doc.text(`Emissão do Pedido: ${new Date(orderData.fiscalEntry).toLocaleDateString()}`, rightColumnX, lineYPos + 16);
	doc.text(`Total do Pedido: ${currencyBRLMask(orderData.invoiceValue)}`, rightColumnX, lineYPos + 22);

	const tableColumn: CellInput[] = [
		{ content: 'Produto', rowSpan: 2, styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Quantidade', colSpan: 2, styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Origem', colSpan: 3, styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Destino', rowSpan: 2, styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
	];

	const tableSubHeader: CellInput[] = [
		{ content: '1º UM', styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: '2º UM', styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Utilizado', styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Saldo', styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
		{ content: 'Localização', styles: { halign: 'center' as HAlignType, valign: 'middle' as VAlignType } },
	];

	const tableRows: CellInput[][] = subgridItems.map((item) => {
		let conversionValue;

		try {
			conversionValue = item.product.conversionType === ConversionType.DIVIDER
				? new Decimal(item.quantity).times(item.product.conversionIndex)
				: new Decimal(item.quantity).dividedBy(item.product.conversionIndex);

			conversionValue = conversionValue.isFinite() && !conversionValue.isNaN() ? conversionValue : '-';
		} catch {
			conversionValue = '-';
		}

		return [
			{ content: `${item.product.code} - ${item.product.description}`, styles: { halign: 'center', valign: 'middle' } },
			{ content: `${item.quantity} ${item.product.measures[0].description}(S)` || '-', styles: { halign: 'center', valign: 'middle' } },
			{ content: `${conversionValue} ${item.product.measures[1].description}(S)` || '-', styles: { halign: 'center', valign: 'middle' } },
			{ content: '-', styles: { halign: 'center', valign: 'middle' } },
			{ content: '-', styles: { halign: 'center', valign: 'middle' } },
			{ content: item.locationOriginBarCode || '-', styles: { halign: 'center', valign: 'middle' } },
			{ content: item.locationDestinyBarCode || '-', styles: { halign: 'center', valign: 'middle' } },
		];
	});

	autoTable(doc, {
		head: [tableColumn, tableSubHeader],
		body: tableRows,
		startY: lineYPos + 30,
		styles: {
			lineColor: [15, 15, 15],
			lineWidth: 0.1,
			valign: 'middle',
		},
		tableLineColor: [15, 15, 15],
		tableLineWidth: 0.1,
		headStyles: {
			valign: 'middle',
		},
		columnStyles: {
			0: { cellWidth: 'auto' },
			1: { cellWidth: 32 },
			2: { cellWidth: 32 },
			3: { cellWidth: 32 },
			4: { cellWidth: 32 },
			5: { cellWidth: 32 },
			6: { cellWidth: 32 },
		},
		didDrawPage: () => {
			addFooters(doc, doc.internal.pages.length - 1);
		},
	});

	if (typeof doc.putTotalPages === 'function') {
		doc.putTotalPages(totalPagesExp);
	}

	const pdfBlob = doc.output('blob');
	const pdfURL = URL.createObjectURL(pdfBlob);
	window.open(pdfURL, '_blank');
};
