import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import RestoreIcon from '@mui/icons-material/Restore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const fileNameDate = `Rodalog_${new Date().toLocaleDateString().replace(/\//g, '-')}_${new Date().getHours()}-${new Date().getMinutes()}`;

const csvOptions = {
	fileName: fileNameDate,
	delimiter: ';',
	utf8WithBom: true,
};

interface GridToolBarProps {
    onRestoreClick: () => void;
    onToggleFullscreen?: () => void;
}

const GridToolBar = ({ onRestoreClick, onToggleFullscreen }: GridToolBarProps): JSX.Element => (
	<GridToolbarContainer>
		<GridToolbarColumnsButton />
		<GridToolbarExport csvOptions={csvOptions} />
		<Button
			onClick={() => {
				onRestoreClick();
			}}
			startIcon={<RestoreIcon />}
			sx={{ fontSize: '0.835rem' }}
		>
			Restaurar
		</Button>
		{onToggleFullscreen && (
			<Button
				onClick={onToggleFullscreen}
				startIcon={<FullscreenIcon />}
				sx={{ fontSize: '0.835rem' }}
			>
				Tela Cheia
			</Button>
		)}
	</GridToolbarContainer>
);

GridToolBar.defaultProps = {
	onToggleFullscreen: undefined,
};

export const ToolbarComponent = (
	onRestoreClick: () => void,
	onToggleFullscreen?: () => void,
) => () => (
	<GridToolBar onRestoreClick={onRestoreClick} onToggleFullscreen={onToggleFullscreen} />
);

export default GridToolBar;
