import React from 'react';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { currencyBRLMask } from '../helpers/intl';
import { ItemTypeLabels } from './itemTypeLabels';
import { ItemType } from '../enums/ItemType';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { formatValueOrTrace, getColorForValue, getValueOrNullForBonus } from '../helpers/Utils';

export const profitabilityAnalysisFormColumns: GridColDef[] = [
	{ field: 'nrItem', headerName: 'Item', flex: 0.5 },
	{
		field: 'type',
		headerName: 'Tipo',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (
			params: GridValueFormatterParams,
		) => ItemTypeLabels[params.value as ItemType],
	},
	{
		field: 'codeDescription',
		headerName: 'Produto',
		flex: 2,
		cellClassName: 'small-font-cell',
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'quantity',
		headerName: 'Qtd',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'price',
		headerName: 'Preço Unitário',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'total',
		headerName: 'Total Bruto',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'discount',
		headerName: 'Desconto (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'discountValue',
		headerName: 'Vlr Desc',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalWithDiscount',
		headerName: 'Total c/ Desc',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'commission',
		headerName: 'Comissão (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'commissionValue',
		headerName: 'Vlr Comissão',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalDirectCost',
		headerName: 'Custo Direto',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'grossProfitSaleOrder',
		headerName: 'L. Bruto PV',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.grossProfitSaleOrder;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const totalWithDiscount = Number(param.row.totalWithDiscount) || 0;
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				return totalWithDiscount - totalDirectCost;
			});
		},
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalIndirectFinancialCommissionCost',
		headerName: 'Custo Ind. + Fin. + Com. PV.',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, (param) => param
			.row.totalIndirectFinancialCommissionCost),
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitSaleOrder',
		headerName: 'L. Liquido PV',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.netProfitSaleOrder;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const totalPrice = Number(param.row.totalWithDiscount) || 0;
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				const commissionValue = Number(param.row.commissionValue) || 0;
				const totalIndirectFinancialCommissionCost = Number(param
					.row.totalIndirectFinancialCommissionCost) || 0;
				return totalPrice - totalDirectCost
			- commissionValue - totalIndirectFinancialCommissionCost;
			});
		},
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitMarginSaleOrder',
		headerName: 'MLL PV (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.netProfitMarginSaleOrder;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const totalPrice = Number(param.row.totalWithDiscount) || 0;
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				const commissionValue = Number(param.row.commissionValue) || 0;
				const totalIndirectFinancialCommissionCost = Number(param
					.row.totalIndirectFinancialCommissionCost) || 0;
				const netProfitSaleOrder = totalPrice - totalDirectCost
			- commissionValue - totalIndirectFinancialCommissionCost;
				const netProfitMarginSaleOrder = (netProfitSaleOrder / totalPrice) * 100;
				return netProfitMarginSaleOrder;
			});
		},
		valueFormatter: (params) => formatValueOrTrace(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => {
			const value = params.value as unknown as number;
			const color = getColorForValue(value);
			return (
				<div style={{ color }}>
					<TruncateTooltipCell value={params.formattedValue as string} />
				</div>
			);
		},
	},
	{
		field: 'simulationPrice',
		headerName: 'Total Simulação',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, () => {
			const quantity = Number(params.row.quantity || 1);
			const discountValue = Number(params.row.discountValue);
			return (Number(params.row.simulationPrice) * quantity) - discountValue;
		}),
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'grossProfitSimulation',
		headerName: 'L. Bruto Sim.',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.grossProfitSimulation;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const quantity = Number(params.row.quantity || 1);
				const simulationPrice = Number(param.row.simulationPrice) || 0;
				const discountValue = Number(param.row.discountValue);
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				return (simulationPrice * quantity) - discountValue - totalDirectCost;
			});
		},
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalSimulationIndirectFinancialCommissionCost',
		headerName: 'Custo Ind. + Fin. + Com. Sim.',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, (param) => param
			.row.totalSimulationIndirectFinancialCommissionCost),
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitSimulation',
		headerName: 'L. Liquido Sim.',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.netProfitSimulation;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const quantity = Number(params.row.quantity);
				const simulationPrice = Number(param.row.simulationPrice) || 0;
				const totalWithDiscount = (quantity * simulationPrice)
				- (Number(param.row.discountValue) || 0);
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				const commissionValue = Number(param.row.commissionValue) || 0;
				const totalSimulationIndirectFinancialCommissionCost = Number(param
					.row.totalSimulationIndirectFinancialCommissionCost) || 0;
				return totalWithDiscount - totalDirectCost
			- commissionValue - totalSimulationIndirectFinancialCommissionCost;
			});
		},
		valueFormatter: (params) => formatValueOrTrace(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitMarginSimulation',
		headerName: 'MLL Simulação (%)',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => {
			if (params.row.id === 'totalSales' || params.row.id === 'totalBonus') {
				return params.row.netProfitMarginSimulation;
			}
			return getValueOrNullForBonus<number>(params, (param) => {
				const quantity = Number(params.row.quantity);
				const simulationPrice = Number(param.row.simulationPrice) || 0;
				const totalWithDiscount = (quantity * simulationPrice)
				- (Number(param.row.discountValue) || 0);
				const totalDirectCost = Number(param.row.totalDirectCost) || 0;
				const commissionValue = Number(param.row.commissionValue) || 0;
				const totalSimulationIndirectFinancialCommissionCost = Number(param
					.row.totalSimulationIndirectFinancialCommissionCost) || 0;
				const netProfit = totalWithDiscount - totalDirectCost
				- commissionValue - totalSimulationIndirectFinancialCommissionCost;
				return (netProfit / totalWithDiscount) * 100;
			});
		},
		renderCell: (params: GridRenderCellParams<any>) => {
			const value = params.value as number;
			const isValueValid = value !== null && value
			!== undefined && !Number.isNaN(value) && value !== 0;
			const color = isValueValid ? getColorForValue(value) : undefined;
			const formattedValue = isValueValid ? `${value.toFixed(2)}%` : '-';

			return (
				<div style={{ color: color || 'inherit' }}>
					<TruncateTooltipCell value={formattedValue as string} />
				</div>
			);
		},
	},
];
