import React from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { IconButton } from '@mui/material';
import { Print } from '@mui/icons-material';
import { InventoryTaskAction } from '../enums/InventoryTaskAction';
import { InventoryTaskQueryParams } from '../interfaces/InventoryTaskQueryParams';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import { Branch } from '../containers/Task/InventoryTaskAssets';
import { currencyBRLMask } from '../helpers/intl';
import { formatUTCDateToLocal } from '../helpers/DateUtils';
import { statusTask } from './inventoryTask';

export const columnStorageAndExpedition = ({
	filter,
	columnSaleOrderIsVisible,
	handlePrint,
}: {
  filter: InventoryTaskQueryParams;
  columnSaleOrderIsVisible: (type: InventoryTaskAction) => boolean;
  handlePrint(index: number, logoUrl: string): void;
}): Array<MUIDataTableColumnDef> => [
	{
		name: 'status',
		label: 'Status',
		options: {
			customBodyRender: (value: InventoryTaskStatus) => statusTask[value]?.label || 'Status Desconhecido',
		},
	},
	{
		name: 'branch',
		label: 'Empresa/Filial',
		options: {
			customBodyRender: (branch: Branch) => `${branch.company.name} / ${branch.name}`,
		},
	},
	{
		name: 'invoiceNumber',
		label: 'Documento',
	},
	{
		name: 'invoiceType',
		label: filter.type === InventoryTaskAction.SUPPLY ? 'Tipo NFE' : 'Tipo Doc',
	},
	{
		name: 'businessPartnerName',
		label: 'Fornecedor/Cliente',
		options: {
			customBodyRender: (value) => value || '-',
		},
	},
	{
		name: 'invoiceValue',
		label: filter.type === InventoryTaskAction.SUPPLY ? 'Total NFE (R$)' : 'Total Documento',
		options: {
			customBodyRender: (value: number) => currencyBRLMask(value),
		},
	},
	{
		name: 'saleOrder',
		label: 'Pedido de compra',
		options: {
			viewColumns: columnSaleOrderIsVisible(InventoryTaskAction.DISPATCH),
			filter: columnSaleOrderIsVisible(InventoryTaskAction.DISPATCH),
			display: columnSaleOrderIsVisible(InventoryTaskAction.DISPATCH),
		},
	},
	{
		name: 'fiscalEntry',
		label: filter.type === InventoryTaskAction.SUPPLY ? 'Recebimento Fiscal' : 'Emissão Documento',
		options: {
			customBodyRender: (value: Date) => formatUTCDateToLocal(value, 'dd/MM/yyyy'),
		},
	},
	{
		name: 'id',
		label: 'Imprimir',
		options: {
			viewColumns: columnSaleOrderIsVisible(InventoryTaskAction.SUPPLY),
			filter: columnSaleOrderIsVisible(InventoryTaskAction.SUPPLY),
			display: columnSaleOrderIsVisible(InventoryTaskAction.SUPPLY),
			customBodyRender: (_value, tableMeta) => (
				<IconButton
					aria-label="print"
					onClick={() => {
						const logoUrl = `${window.location.origin}/api/configuration/logo`;
						handlePrint(tableMeta.rowIndex, logoUrl);
					}}
				>
					<Print />
				</IconButton>
			),
		},
	},
];
