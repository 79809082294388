import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import {
	ActiveStepCheckout, DanfeByBarCodeQueryParams, IInvoice,
} from './CheckoutCarrierAssets';
import { confirmInvoiceItems, getDanfeByBarCode } from '../../../services/invoice';
import CheckoutCarrierPresentational from '../../../components/Mobile/CheckoutCarrier/CheckoutCarrier';

enum ActionType {
	LOADING,
	INVOICE,
	ACTIVE_STEP
}

interface IState {
	loading: boolean;
	invoice?: IInvoice;
	activeStep: ActiveStepCheckout;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVOICE; payload: { invoice: IInvoice } }
	| { type: ActionType.ACTIVE_STEP; payload: { activeStep: ActiveStepCheckout } }

interface ICheckoutCarrierActions {
	setLoading(loading: boolean): void;
	setActiveStep(activeStep: ActiveStepCheckout): void;
	getDanfeByBarCode(danfeBarCode: string, params?: DanfeByBarCodeQueryParams): void;
	confirmInvoiceItems(invoiceId: string, shippedDate: string): void;
}

const initialState: IState = {
	loading: false,
	activeStep: ActiveStepCheckout.BARCODE,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVOICE:
			return {
				...state,
				invoice: action.payload.invoice,
			};
		case ActionType.ACTIVE_STEP:
			return {
				...state,
				activeStep: action.payload.activeStep,
			};
		default:
			throw new Error();
	}
};

const CheckoutCarrierActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ICheckoutCarrierActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setActiveStep(activeStep: ActiveStepCheckout) {
			dispatch({ type: ActionType.ACTIVE_STEP, payload: { activeStep } });
		},
		getDanfeByBarCode(danfeBarCode: string, params: DanfeByBarCodeQueryParams) {
			actions.setLoading(true);
			getDanfeByBarCode(danfeBarCode, params).then((response) => {
				dispatch({
					type: ActionType.INVOICE,
					payload: {
						invoice: response.data,
					},
				});
				actions.setActiveStep(ActiveStepCheckout.PRODUCTS);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		confirmInvoiceItems(invoiceId: string, shippedDate: string) {
			actions.setLoading(true);
			confirmInvoiceItems(invoiceId, shippedDate).then((response) => {
				enqueueSnackbar(response.data.message || 'Checkout da nota fiscal confirmada com sucesso', { variant: 'success' });
				actions.setActiveStep(ActiveStepCheckout.BARCODE);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const CheckoutCarrier = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();

	const actions = useMemo(
		() => CheckoutCarrierActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	return (
		<CheckoutCarrierPresentational
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...state}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...actions}
		/>
	);
};

export default CheckoutCarrier;
