import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { filterObject } from '../../helpers/Utils';
import { StatusSaleOrderQueryParams } from '../../interfaces/StatusSaleOrderParams';
import Autocomplete from '../Common/Form/Autocomplete';
import { StatusOperation } from '../../containers/StatusSaleOrder/StatusSaleOrderAssets';

interface IFilterProps {
	sendFilter: (values: Partial<StatusSaleOrderQueryParams>) => void;
}

interface IForm {
	code?: string;
    statusOperation?: StatusOperation[];
    daysBack?: number;
}

export const statusList = [
	{ id: StatusOperation.RELEASED, label: 'Liberado' },
	{ id: StatusOperation.OPEN, label: 'Aberto' },
	{ id: StatusOperation.INVOICED, label: 'Faturado' },
	{ id: StatusOperation.SHIPPED, label: 'Expedido' },
];

const StatusSaleOrderFilter = ({
	initialValues,
	sendFilter,
}: IFilterProps & { initialValues: IForm }): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter(filterObject(values));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			{({ handleReset }) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12}>
							<Input.InputField
								id="code"
								name="code"
								label="Pedido"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								label="Status"
								name="statusOperation"
								valueKey="id"
								valueLabel="id"
								labelKey="label"
								options={statusList}
								multiple
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="daysBack"
								name="daysBack"
								label="Dias retroativos"
								autoComplete="off"
								type="number"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(handleReset)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default StatusSaleOrderFilter;
