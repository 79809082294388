import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatValueOrTrace } from '../helpers/Utils';
import { applyAppropriateMask, applyPhoneMask } from '../helpers/masks';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { BusinessPartnerType } from '../enums/BusinessPartnerType';
import { LegalEntityType } from '../enums/LegalEntityType';
import { BusinessPartnerTypeLabels } from './businessPartnerTypeLabels';
import { BusinessPartnerSubtype } from '../enums/BusinessPartnerSubtype';
import { ContractType } from '../enums/ContractType';
import { CreditRiskType } from '../enums/CredityRiskType';
import { ContractTypeLabels } from './contractTypeLabes';
import { CreditRiskTypeLabels } from './creditRiskTypeLabels';
import { LegalEntityTypeLabels } from './legalEntityLabels';
import { BusinessPartnerSubtypeLabels } from './businessPartnerSubtypeLabels';

export const businessPartnerColumns: GridColDef[] = [
	{
		field: 'type',
		headerName: 'Tipo de Parceiro',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<BusinessPartnerType>) => (
			params.value != null ? <TruncateTooltipCell value={BusinessPartnerTypeLabels[params.value]} /> : '-'
		),
	},
	{
		field: 'subtype',
		headerName: 'Subtipo',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<BusinessPartnerSubtype>) => (
			params.value != null ? <TruncateTooltipCell value={BusinessPartnerSubtypeLabels[params.value]} /> : '-'
		),
	},
	{
		field: 'name',
		headerName: 'Nome',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'marketName',
		headerName: 'Nome de Mercado',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'country',
		headerName: 'País',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'state',
		headerName: 'Estado',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'city',
		headerName: 'Cidade',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'legalEntity',
		headerName: 'Entidade Legal',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<LegalEntityType>) => (
			params.value != null ? <TruncateTooltipCell value={LegalEntityTypeLabels[params.value]} /> : '-'
		),
	},
	{
		field: 'taxId',
		headerName: 'CPF/CNPJ',
		flex: 1,
		sortable: false,
		valueFormatter: (params) => formatValueOrTrace(params, applyAppropriateMask),
	},
	{
		field: 'stateTaxId',
		headerName: 'Inscrição Estadual',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'cityTaxId',
		headerName: 'Inscrição Municipal',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'zipCode',
		headerName: 'CEP',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params, applyAppropriateMask),
	},
	{
		field: 'address',
		headerName: 'Endereço',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'district',
		headerName: 'Bairro',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'number',
		headerName: 'Número',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'email',
		headerName: 'E-mail',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'phone',
		headerName: 'Telefone',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params, applyPhoneMask),
	},
	{
		field: 'approved',
		headerName: 'Aprovado',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<boolean>) => (
			params.value ? 'Sim' : 'Não'
		),
	},
	{
		field: 'addressOne',
		headerName: 'Complemento',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'website',
		headerName: 'Site',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'invoiceMail',
		headerName: 'E-mail Fiscal',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrTrace(params),
	},
	{
		field: 'creditRisk',
		headerName: 'Risco de Crédito',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<CreditRiskType>) => (
			params.value != null ? <TruncateTooltipCell value={CreditRiskTypeLabels[params.value]} /> : '-'
		),
	},
	{
		field: 'creditValue',
		headerName: 'Valor de Crédito',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? `R$ ${params.value}` : '-'),
	},
	{
		field: 'creditDate',
		headerName: 'Data de Crédito',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value != null ? new Date(params.value).toLocaleDateString() : '-'),
	},
	{
		field: 'groupCredit',
		headerName: 'Crédito em Grupo',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<boolean>) => (
			params.value ? 'Sim' : 'Não'
		),
	},
	{
		field: 'contractType',
		headerName: 'Tipo de Contrato',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<ContractType>) => (
			params.value != null ? <TruncateTooltipCell value={ContractTypeLabels[params.value]} /> : '-'
		),
	},
];
