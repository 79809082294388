import React, {
	useEffect,
	useCallback,
} from 'react';
import {
	Routes, Route, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
// import Inventory from "./components/Inventory/Inventory";
// import InventoryContainer from "./components/Inventory/InventoryContainer";
import Login from './containers/Login';
import Home from './containers/Home';
import Provider from './containers/Provider/Provider';
import ProviderEdit from './containers/Provider/ProviderEdit';
import Client from './containers/Client/Client';
import ClientEdit from './containers/Client/ClientEdit';
import Company from './containers/Company/Company';
import CompanyEdit from './containers/Company/CompanyEdit';
import Branch from './containers/Branch/Branch';
import BranchEdit from './containers/Branch/BranchEdit';
import UserContainer from './containers/User/User';
import UserEditContainer from './containers/User/UserEdit';
import PurchaseOrderApproval from './containers/Order/PurchaseOrderApproval';
import PurchaseOrder from './containers/Order/PurchaseOrder';
import BudgetDashboard from './containers/Dashboards/BudgetDashboard';
import BudgetUsers from './containers/Budget/BudgetUsers';
import Product from './containers/Product/Product';
import ProductEdit from './containers/Product/ProductEdit';
import { isAuthenticated } from './services/auth';
import DefaultLayout from './components/Common/Layouts/DefaultLayout';
import { UnauthorizedPage } from './components/Common/UnauthorizedPage';
import PageNotFound from './components/Common/PageNotFound';
import { hasPermission } from './helpers/permission';
import BudgetResponsible from './containers/Budget/BudgetResponsible';
import License from './containers/License/License';
import LicenseEdit from './containers/License/LicenseEdit';
import AccessGroup from './containers/AccessGroup/AccessGroup';
import AccessGroupEdit from './containers/AccessGroup/AccessGroupEdit';
import HourProjectDashboard from './containers/Dashboards/HourProjectDashboard';
import Appointment from './containers/HourProject/Appointment';
import HourProjectInconsistencies from './containers/HourProject/HourProjectInconsistencies';
import UserTasksAppointedHours from './containers/HourProject/UserTasksAppointedHours';
import BusinessPartner from './containers/BusinessPartner/BusinessPartner';
import BusinessPartnerEdit from './containers/BusinessPartner/BusinessPartnerEdit';
import ClockHours from './containers/HourProject/ClockHours';
import SaleOrder from './containers/SaleOrder/SaleOrder';
import SaleOrderEdit from './containers/SaleOrder/SaleOrderEdit';
import InventoryPendingTask from './containers/Task/InventoryPendingTask';
import { getMenu } from './services/app';
import { Role } from './interfaces/Module';
import MobileLayout from './components/Common/Layouts/MobileLayout';
import HomeMobile from './components/Mobile/Home';
import usePermission from './hooks/usePermission';
import Location from './containers/Location/Location';
import LocationEdit from './containers/Location/LocationEdit';
import LocationTypeEdit from './containers/LocationType/LocationTypeEdit';
import LocationType from './containers/LocationType/LocationType';
import Expedition from './containers/Mobile/Expedition/Expedition';
import StorageCheck from './containers/Mobile/Storage/StorageCheck';
import Storage from './containers/Mobile/Storage/Storage';
import SalePriceFormation from './containers/SalePriceFormation/SalePriceFormation';
import SalePriceFormationEdit from './containers/SalePriceFormation/SalePriceFormationEdit';
import Transfer from './containers/Mobile/Transfer/Transfer';
import InventoryMobile from './containers/Mobile/Inventory/Inventory';
import Inventory from './containers/Inventory/Inventory';
import InventoryTasks from './containers/Inventory/InventoryTasks';
import InventoryStart from './containers/Inventory/InventoryStart';
import InventoryMobileDetails from './containers/Mobile/Inventory/InventoryDetails';
import InventoryFinish from './containers/Mobile/Inventory/InventoryFinish';
import InventorySummaryByOperatorReport from './containers/Inventory/InventorySummaryByOperatorReport';
import ProfitabilityAnalysis from './containers/ProfitabilityAnalysis/ProfitabilityAnalysis';
import ProfitabilityAnalysisEdit from './containers/ProfitabilityAnalysis/ProfitabilityAnalysisEdit';
import InventorySummaryReport from './containers/Inventory/InventorySummaryReport';
import InventoryDifferenceReport from './containers/Inventory/InventoryDifferencesReport';
import BusinessIntelligence from './containers/BusinessInteligence/BusinessInteligence';
import CompanyBranchSelection from './containers/Mobile/CompanyBranchSelection/CompanyBranchSelection';
import StatusSaleOrder from './containers/StatusSaleOrder/StatusSaleOrder';
import CheckoutCarrier from './containers/Mobile/CheckoutCarrier/CheckoutCarrier';

const RequireAuth = ({ children, redirectTo }: { [x: string]: any }): JSX.Element => {
	const location = useLocation();

	return isAuthenticated() ? (
		children
	) : (
		<Navigate to={redirectTo} state={{ from: location }} />
	);
};

const ProtectedRoute = ({
	children, module, edit, admin,
}: {
	[x: string]: any
} & Role): JSX.Element => (
	hasPermission({ module, edit, admin }) ? (children) : (<UnauthorizedPage />)
);

ProtectedRoute.defaultProps = {
	edit: false,
	admin: false,
};

const ValidateRoute = ({
	children, redirectTo, module, edit, admin,
}: { [x: string]: any } & Role): JSX.Element => {
	const location = useLocation();

	if (!isAuthenticated()) {
		return <Navigate to={redirectTo} state={{ from: location }} />;
	}

	if (!hasPermission({ module, edit, admin })) {
		return <UnauthorizedPage />;
	}

	return children;
};

ValidateRoute.defaultProps = {
	edit: false,
	admin: false,
};

const RoutesComponent = ({ name }: { name: string }): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();
	const companyPermission = usePermission('COMPANY');
	const logout = useCallback(() => navigate('/login'), [navigate]);

	useEffect(() => {
		const currentModule = getMenu().find((module) => location.pathname.includes(module.link));
		document.title = currentModule ? `${currentModule?.name} | ${name}` : name;
	}, [location, name]);

	useEffect(() => {
		window.addEventListener('logout', logout, false);

		return () => {
			window.removeEventListener('logout', logout, false);
		};
	}, [logout]);

	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/app" element={<MobileLayout />}>
				<Route
					path=""
					element={(
						<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
							<HomeMobile />
						</ValidateRoute>
					)}
				/>
				<Route
					path="storage-check"
				>
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<StorageCheck />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route
					path="storage"
				>
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<Storage />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route
					path="expedition"
				>
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<Expedition />
							</ValidateRoute>
						)}
					/>
				</Route>

				<Route path="transfer">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<Transfer />
							</ValidateRoute>
						)}
					/>
				</Route>

				<Route path="company-branch-selection">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<CompanyBranchSelection />
							</ValidateRoute>
						)}
					/>
				</Route>

				<Route path="checkout-carrier">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<CheckoutCarrier />
							</ValidateRoute>
						)}
					/>
				</Route>

				<Route path="inventory">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryMobile />
							</ValidateRoute>
						)}
					/>

					<Route
						path=":id"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryMobileDetails />
							</ValidateRoute>
						)}
					/>

					<Route
						path=":id/finish"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryFinish />
							</ValidateRoute>
						)}
					/>
				</Route>
			</Route>
			<Route path="/signup" element={<h1>SignUp</h1>} />
			<Route element={<DefaultLayout />}>
				<Route
					path=""
					element={(
						<RequireAuth redirectTo="/login">
							<Home />
						</RequireAuth>
					)}
				/>
				<Route path="provider">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="Provider">
								<Provider />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="PROVIDER" edit>
									<ProviderEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="PROVIDER" edit>
									<ProviderEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="client">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="CLIENT">
								<Client />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="CLIENT" edit>
									<ClientEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="CLIENT" edit>
									<ClientEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="company">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="COMPANY">
								<Company />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="COMPANY" edit>
									<CompanyEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="COMPANY" edit>
									<CompanyEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="branch">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="BRANCH">
								<Branch />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="BRANCH" edit>
									<BranchEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id/:companyId"
							element={(
								<ValidateRoute redirectTo="/login" module="BRANCH" edit>
									<BranchEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="user">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="USER">
								<UserContainer />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="USER" edit>
									<UserEditContainer />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="USER" edit>
									<UserEditContainer />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="budget">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="BUDGET">
								<BudgetDashboard />
							</ValidateRoute>
						)}
					/>
					<Route path="users">
						<Route
							index
							element={(
								<ValidateRoute redirectTo="/login" module="BUDGET" edit>
									<BudgetUsers />
								</ValidateRoute>
							)}
						/>
						<Route path="responsible">
							<Route
								path=":userId"
								element={(
									<ValidateRoute redirectTo="/login" module="BUDGET" edit>
										<BudgetResponsible />
									</ValidateRoute>
								)}
							/>
							<Route path="*" element={<PageNotFound />} />
						</Route>
					</Route>
				</Route>
				<Route path="access-group">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="ACCESS_GROUP">
								<AccessGroup />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="ACCESS_GROUP" edit>
									<AccessGroupEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="ACCESS_GROUP" edit>
									<AccessGroupEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="order">
					<Route
						index
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrderApproval manage />
							</ValidateRoute>
						)}
					/>
					<Route
						path="approval"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrderApproval />
							</ValidateRoute>
						)}
					/>
					<Route
						path="apportionment/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrder apportionment />
							</ValidateRoute>
						)}
					/>
					<Route
						path="apportionment"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrder />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route path="license">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="LICENSE">
								<License />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="LICENSE" edit>
									<LicenseEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="LICENSE" edit>
									<LicenseEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="hour-project">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="HOUR_PROJECT" admin>
								<HourProjectDashboard />
							</ValidateRoute>
						)}
					/>
					<Route
						path="manage-hours"
						element={(
							<ValidateRoute redirectTo="/login" module="HOUR_PROJECT" admin>
								<HourProjectInconsistencies />
							</ValidateRoute>
						)}
					/>
					<Route
						path="clock-hours"
						element={(
							<ValidateRoute redirectTo="/login" module="HOUR_PROJECT">
								<ClockHours />
							</ValidateRoute>
						)}
					/>
					<Route
						path="appointment"
						element={(
							<ValidateRoute redirectTo="/login" module="TASK_CLOCK_IN_OUT">
								<Appointment />
							</ValidateRoute>
						)}
					/>
					<Route
						path="manage-hours/edit/inconsistence"
						element={(
							<ValidateRoute redirectTo="/login" module="TASK_CLOCK_IN_OUT" edit>
								<UserTasksAppointedHours />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route
					path="business-intelligence"
					element={(
						<RequireAuth redirectTo="/login">
							{(companyPermission?.isAdmin) ? (
								<BusinessIntelligence />
							) : (
								<UnauthorizedPage />
							)}
						</RequireAuth>
					)}
				/>
				<Route path="business-partner">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="BUSINESS_PARTNER">
								<BusinessPartner />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="BUSINESS_PARTNER" edit>
									<BusinessPartnerEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="BUSINESS_PARTNER" edit>
									<BusinessPartnerEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="product">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="PRODUCT">
								<Product />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="PRODUCT" edit>
									<ProductEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="PRODUCT" edit>
									<ProductEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="sale-order">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="SALE_ORDER">
								<SaleOrder />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_ORDER" edit>
									<SaleOrderEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_ORDER" edit>
									<SaleOrderEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="location">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="LOCATION">
								<Location />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="LOCATION" edit>
									<LocationEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="LOCATION" edit>
									<LocationEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="location-type">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="LOCATION_TYPE">
								<LocationType />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="LOCATION_TYPE" edit>
									<LocationTypeEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="LOCATION_TYPE" edit>
									<LocationTypeEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="inventory-task">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryPendingTask />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route path="inventory">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<Inventory />
							</ValidateRoute>
						)}
					/>

					<Route
						path="start"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryStart />
							</ValidateRoute>
						)}
					/>

					<Route
						path="edit/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryTasks />
							</ValidateRoute>
						)}
					/>

					<Route
						path="summary/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventorySummaryReport />
							</ValidateRoute>
						)}
					/>

					<Route
						path="report-by-operator/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventorySummaryByOperatorReport />
							</ValidateRoute>
						)}
					/>

					<Route
						path="difference/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="INVENTORY_TASK">
								<InventoryDifferenceReport />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route path="sale-price-formation">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="SALE_PRICE_FORMATION">
								<SalePriceFormation />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_PRICE_FORMATION" edit>
									<SalePriceFormationEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_PRICE_FORMATION" edit>
									<SalePriceFormationEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="profitability-analysis">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="SALE_ORDER_SIMULATION">
								<ProfitabilityAnalysis />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_ORDER_SIMULATION" edit>
									<ProfitabilityAnalysisEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="SALE_ORDER_SIMULATION" edit>
									<ProfitabilityAnalysisEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="status-sale-order">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="INVOICE">
								<StatusSaleOrder />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Route>
		</Routes>
	);
};

export default RoutesComponent;
