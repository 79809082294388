export enum StatusOperation {
    RELEASED, // Liberado
    OPEN, // Aberto
    INVOICED, // Faturado
    SHIPPED // Expedido
}

export interface IStatusSaleOrder {
    id: string;
    code: string;
    clientCode: string;
    clientBranch: string;
    clientName: string;
    clientTaxId: string;
    courierName: string;
    emissionDate: Date | string;
    totalOrder: number;
    statusOperation: StatusOperation;
}

export interface StatusCount {
    statusOperation: StatusOperation;
    count: number;
}

export interface StatusSaleOrderResponse {
    data: IStatusSaleOrder[];
    count: number;
    statusCounts: StatusCount[];
}
