import React, { useMemo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const routes = [
	{ name: 'Fornecedor', path: '/provider' },
	{ name: 'Cliente', path: '/client' },
	{ name: 'Usuário', path: '/user' },
	{ name: 'Filial', path: '/branch' },
	{ name: 'Empresa', path: '/company' },
	{ name: 'Orçamento', path: '/budget' },
	{ name: 'Licença', path: '/license' },
	{ name: 'Usuários do Orçamento', path: '/budget/users' },
	{ name: '% do responsável', path: '/budget/users/responsible' },
	{ name: 'Pedido', path: '/order' },
	{ name: 'Aprovação', path: '/order/approval' },
	{ name: 'Rateio', path: '/order/apportionment' },
	{ name: 'Grupo de Acesso', path: '/access-group' },
	{ name: 'Registro de horas de projeto', path: '/hour-project/appointment' },
	{ name: 'Projeto', path: '/hour-project' },
	{ name: 'Apontamentos de projeto', path: '/hour-project/manage-hours' },
	{ name: 'Ponto do Usuário', path: '/hour-project/clock-hours' },
	{ name: 'Business Intelligence', path: '/business-intelligence' },
	{ name: 'Produto', path: '/product' },
	{ name: 'Business Partner', path: '/business-partner' },
	{ name: 'Ordens de Venda', path: '/sale-order' },
	{ name: 'Localização', path: '/location' },
	{ name: 'Tipo de Localização', path: '/location-type' },
	{ name: 'Formação de Preço de Venda', path: '/sale-price-formation' },
	{ name: 'Inventário', path: '/inventory' },
	{ name: 'Gestão de Tarefas', path: '/inventory-task' },
	{ name: 'Análise de Rentabilidade', path: '/profitability-analysis' },
	{ name: 'Status do Pedido', path: '/status-sale-order' },
];

const Breadcrumb = (): JSX.Element => {
	const location = useLocation();

	const fullPath = useMemo(() => {
		const pathSegments = location.pathname.split('/').filter(Boolean);

		return pathSegments.map((segment, index) => {
			const reconstructPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
			const matchingRoute = routes.find((route) => route.path === reconstructPath);
			if (segment === 'edit' && index === pathSegments.length - 1) {
				return { name: 'Adicionar', path: reconstructPath };
			}
			if ((index === pathSegments.length - 1 && segment !== 'edit' && !matchingRoute) || (pathSegments.includes('edit') && index === pathSegments.length - 1)) {
				return { name: 'Editar', path: reconstructPath };
			}
			return matchingRoute
				? { name: matchingRoute.name, path: reconstructPath }
				: null;
		}).filter(Boolean);
	}, [location.pathname]);

	return (
		<Breadcrumbs aria-label="breadcrumb">
			<Link underline="hover" color="text.primary" component={RouterLink} to="/">
				<HomeIcon color="primary" />
			</Link>
			{fullPath.map((route, index) => {
				if (route) {
					return index === fullPath.length - 1
						? <span key={route.path} aria-current="page">{route.name}</span>
						: (
							<Link
								key={route.path}
								underline="hover"
								color="text.primary"
								component={RouterLink}
								to={route.path}
							>
								{route.name}
							</Link>
						);
				}
				return null;
			})}
		</Breadcrumbs>
	);
};

export default Breadcrumb;
